import {
  useColorScheme, styled
} from "@mui/material-next/styles";
import {
  useState,
  useEffect,
  SyntheticEvent,
  useMemo
} from "react";
import Stack from "@mui/material/Stack";
import { Tabs, Tab } from "@mui/material-next";
import AppBar from "@mui/material/AppBar";
import type { SxProps, Theme } from "@mui/material/";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Locale } from "../../../../localization/types";
import {
  Menu, Typography,
  useTheme,
  useMediaQuery
} from "@mui/material";
import Box from "@mui/material/Box";
import useAppState from "../../../../store/appState/useAppState";
import {
  setDAPPOpen,
  setMenuValue
} from "../../../../store/appState/appStateActions";
import SvgIcon from "@mui/material/SvgIcon";
import { LogoImage } from "../../../UI/Logo/Logo";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import DarkIcon from "@mui/icons-material/DarkModeOutlined";
import LightIcon from "@mui/icons-material/LightModeOutlined";
import { US, CN, JP } from "country-flag-icons/react/3x2";
import NoDaemon from "../../NoDaemon/index";
import Proxy from "../CONET-Proxy/index";
import Miner from "../miner/index";
import HubIcon from "@mui/icons-material/Hub";
import NodeExplorer from "../nodeExplorer/nodeExplorer";
import Container from "@mui/material/Container";
import store, { useTypedSelector } from "../../../../store/store";
import CloudNode from "../CloudNode/CloudNode";
import { FormattedMessage, useIntl } from "react-intl";
import Conet_Logo_Dark from "../../../../assets/images/conet-logo-dark.png";
import Conet_Logo_Light from "../../../../assets/images/conet-logo-light.png";
import { ReactComponent as IconDashboard } from "../../../../assets/images/icon-dashboard.svg";
import { ReactComponent as IconApps } from "../../../../assets/images/icon-apps.svg";
import { ReactComponent as IconClaim } from "../../../../assets/images/icon-claim.svg";
import { ReactComponent as ConetAvatar } from "../../../../assets/images/con-avatar.svg";
import { ReactComponent as IconSwap } from "../../../../assets/images/swap.svg";
import { ReactComponent as IconChat } from "../../../../assets/images/chat.svg";
import { ReactComponent as IconGraph } from "../../../../assets/images/graph.svg";
import { ReactComponent as IconUsa } from "../../../../assets/images/usa.svg";
import { ReactComponent as IconChina } from "../../../../assets/images/china.svg";
import { ReactComponent as IconLightTheme } from "../../../../assets/images/lighttheme.svg";
import { ReactComponent as IconDarkTheme } from "../../../../assets/images/darktheme.svg";

import { ReactComponent as IconArrowRight } from "../../../../assets/images/arrow-right.svg";
import { ReactComponent as IconArrowLeft } from "../../../../assets/images/arrow-left.svg";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ProfileTest from "../profileTEST/index";
import { WelcomeToConet } from "./WelcomeToConet";
import { IProfile } from "../profileTEST/interface/profile.interface";
import { listeningVersionHook } from "../../../../API/platform";

import FooterPlatform from './footerPlatform';
import { motion, useMotionTemplate, useMotionValue, animate } from "framer-motion";
import { useUserProfile } from "../../../../API/useUserProfile";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../../API/useLanguage";
import Swap from '../swapFeatures/swap';
import Claim from '../claim';
import AdminMonitor from "../adminMonitor";
import { useRefreshStore } from "../../../../API/useRefreshStore";
import SwapFeatures from '../swapFeatures';
import Chat from '../Chat';

interface StyledTabsProps {
  children?: React.ReactNode;
  value?: number;
  onChange?: (event: React.SyntheticEvent, newValue: number) => void;
}

const StackContainer = styled(Stack)(() => ({
  width: "100%",
  height: '100vh'
}));

interface StyledTabProps {
  label?: string;
  icon?:
  | string
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  sx?: SxProps<Theme>;
}

interface NetworkInterface {
  isCollapsed: boolean;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    orientation="vertical"
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
  },
});

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  "&.Mui-selected": {},
  "&.Mui-focusVisible": {},
}));

const LogoDark = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  backgroundImage: `url(${Conet_Logo_Dark})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const LogoLight = styled(Box)(({ theme }) => ({
  width: "48px",
  height: "48px",
  backgroundImage: `url(${Conet_Logo_Light})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const MenuOption = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  cursor: "pointer",
  padding: '8px 16px',
  borderRadius: '16px'
  // "&:hover": {
  //   color: "#3399FF",
  //   fontWeight: "bold",
  // },
}));

const ThemeOption = styled(Box)(({ theme }) => ({
  width: "32px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100%",
  cursor: "pointer",
}));

//display="flex" justifyContent="space-between" alignItems="center"
const LanguageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  cursor: "pointer",
  gap: "8px",
}));

const NetworkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "50px",
  padding: "0.25rem 1rem",
  lineHeight: "24px",
  fontSize: "16px",
  justifyContent: "space-between",
  cursor: 'pointer'
}));

//display="flex" gap="0.5rem" alignItems="center" p="1rem" borderRadius="1rem"
const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "0.5rem",
  alignItems: "center",
  padding: "1rem",
  borderRadius: "1rem",
  cursor: 'pointer'
}));

type action = {
  icon: any;
  name: Locale;
  description: string;
  active: boolean;
};

const actions: action[] = [
  {
    icon: IconChina,
    name: "zh",
    description: "中文",
    active: true
  },
  {
    icon: IconUsa,
    name: "en",
    description: "English",
    active: true
  },
];

const showLocationIcon = (locale: Locale) => {
  switch (locale) {
    default:
    case "en": {
      return <SvgIcon component={US} inheritViewBox />;
    }
    case "ja-JP": {
      return <SvgIcon component={JP} inheritViewBox />;
    }
    case "zh": {
      return <SvgIcon component={CN} inheritViewBox />;
    }
  }
};

const NetworkSwitcher = (props: NetworkInterface) => {
  const { mode, setMode } = useColorScheme();
  const { setTheme, theme } = useAppState()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const ModalSelector = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    height: "40px!important",
    lineHeight: '32px',
    alignItems: "center",
    display: "flex",
    borderRadius: "16px",
    justifyContent: "space-between",
    "&:hover": {
      background: mode === "light" ? "#F6F8FF" : "#363E59",
    },
  }));

  useEffect(() => {
    if (localStorage.getItem('mui-mode') == 'light') {
      setMode(`light`);
      setTheme("Light");
    } else {
      setMode(`dark`);
      setTheme("Dark");
    }
  }, [mode]);

  const networkMenuClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <Box position="relative" flexGrow={1}>

      {/* <NetworkBox
        onClick={networkMenuClick}
        bgcolor={mode === "light" ? "#F6F8FF" : "#303032"}
      >

        <SvgIcon
          cursor="pointer"
          component={IconNetworkGlobe}
          inheritViewBox
          sx={{ fontSize: 22 }}
        />
        {props.isCollapsed ? null :
          <Typography fontWeight="bold">
            <FormattedMessage
              id="sidebar.mainnetwork"
              defaultMessage="Conet Network" />
          </Typography>
        }

        <SvgIcon
          component={ExpandMoreIcon}
          inheritViewBox
          sx={{ fontSize: 16 }}
        />
      </NetworkBox> */}
      {isDropdownOpen ? (
        <ClickAwayListener onClickAway={handleCloseDropdown}>
          <Box
            width="440px"
            maxHeight="208px"
            position="absolute"
            top={0}
            left={props.isCollapsed ? '60px' : "190px"}
            zIndex={9999}
            bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
            display="flex"
            flexDirection="column"
            borderRadius="16px"
            boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
            p="1rem"
            gap="32px"
          >
            <Stack justifyContent="space-between" direction="row">
              <Typography fontWeight="bold">
                <FormattedMessage
                  id="sidebar.networktext"
                  defaultMessage="Network"
                />
              </Typography>
              <SvgIcon
                cursor="pointer"
                onClick={handleCloseDropdown}
                component={CloseIcon}
                inheritViewBox
                sx={{ fontSize: 16 }}
              />
            </Stack>
            <Stack direction="column">
              <ModalSelector px="1rem">
                Conet Network
                <SvgIcon
                  cursor="pointer"
                  component={CheckIcon}
                  inheritViewBox
                  sx={{ fontSize: 24 }}
                />
              </ModalSelector>

              <Box display="flex" gap="8px" color="#577DFF" paddingTop="32px">
                <SvgIcon
                  component={AddCircleOutlineIcon}
                  inheritViewBox
                  sx={{ fontSize: 24 }}
                />
                <Typography fontWeight="bold" fontSize="16px">
                  <FormattedMessage
                    id="sidebar.networktext"
                    defaultMessage="Add network"
                  />
                </Typography>
              </Box>
            </Stack>
          </Box>
        </ClickAwayListener>
      ) : null}
    </Box>
  );
};

const LanguageSwitcher = (isCollapsed: any) => {
  const intl = useIntl();
  const { locale, setLocale } = useAppState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { mode, setMode } = useColorScheme();
  /* const {i18n} = useTranslation() */
  const { changeLanguage } = useLanguage()
  const { t } = useTranslation()
  const { i18n } = useTranslation();

  const languageIcon = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return IconUsa;
      case "zh":
        return IconChina;
      default:
        return IconChina;
    }
  }, [i18n.language]);

  const languageName = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return "English";
      case "zh":
        return "中文";
      default:
        return "中文";
    }
  }, [i18n.language]);

  const LanguageSelector = styled(Box)(({ theme }) => ({
    cursor: "pointer",
    height: "40px",
    alignItems: "center",
    display: "flex",
    borderRadius: "16px",
    gap: "8px",
    justifyContent: "flex-start",
    "&:hover": {
      background: mode === "light" ? "#F6F8FF" : "#363E59",
    },
  }));

  const languageMenuClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <Box position="relative" flexGrow={1} width="100%">
      <LanguageBox onClick={languageMenuClick}>
        <SvgIcon
          cursor="pointer"
          component={languageIcon}
          inheritViewBox
          sx={{ fontSize: 32 }}
        />
        <Box fontWeight='bold'>{isCollapsed ? "" : languageName}</Box>
      </LanguageBox>

      {isDropdownOpen ? (
        <ClickAwayListener onClickAway={handleCloseDropdown}>
          <Box
            width="200px"
            height="180px"
            position="absolute"
            bottom="0px"
            left={isCollapsed ? "50px" : "150px"}
            zIndex={9999}
            bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#262527"}
            border={'1px solid white'}
            display="flex"
            flexDirection="column"
            borderRadius="16px"
            boxShadow="0px 0px 21px 0px rgba(0,0,0,0.1)"
            p="1rem"
            gap="32px"
          >
            <Stack justifyContent="space-between" direction="row">
              <Typography fontWeight='bold' >{t('sideBar.language')}</Typography>
              <SvgIcon
                cursor="pointer"
                onClick={handleCloseDropdown}
                component={CloseIcon}
                inheritViewBox
                sx={{ fontSize: 16 }}
              />
            </Stack>

            <Stack direction="column" gap="8px">
              {actions.map((language, index) => (
                <LanguageSelector
                  key={index}
                  px="1rem"
                  onClick={() => {
                    if (language.active)
                      changeLanguage(language.name)
                  }}
                >
                  <SvgIcon
                    cursor="pointer"
                    component={language.icon}
                    inheritViewBox
                    sx={{ fontSize: 32 }}
                  />

                  {language.description}

                  {locale === language.name ? (
                    <SvgIcon
                      cursor="pointer"
                      component={CheckIcon}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  ) : null}
                </LanguageSelector>
              ))}
            </Stack>
          </Box>
        </ClickAwayListener>
      ) : null}
    </Box>
  );
};

type MobileSIdeBarProps = {
  handleChange: (event: SyntheticEvent | null, newValue: number) => any;
  profile?: any,
  currentProfile?: any,
};
const MobileSidebar: React.FC<MobileSIdeBarProps> = ({ handleChange, profile, currentProfile }) => {
  const { isUnlocked } = useAppState();
  const { mode, setMode } = useColorScheme();
  const { t } = useTranslation()
  const showAdminMonitor = useRefreshStore(state => state.showAdminMonitor)

  const MenuItem = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    fontSize: "12px",
    alignItems: "center",
    justifyContent: "center",
    height: "72px",
    gap: "4px",
    flexGrow: 1,
    cursor: "pointer",
    "&:hover": {
      color: "#3399FF",
      fontWeight: "bold",
    },
    zIndex: 99,
  }));

  return isUnlocked ? (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
      height="72px"
      zIndex={9999}
      display={{
        xs: "flex",
        sm: "flex",
        md: "flex",
        xm: "none",
        lg: "none",
        xl: "none",
      }}
      borderTop={localStorage.getItem('mui-mode') === "light" ? "1px solid #E4E2E4" : "1px solid #474648"}
      justifyContent="space-between"
    >
      <MenuItem onClick={(event) => handleChange(event, 0)}>
        <SvgIcon
          component={IconDashboard}
          inheritViewBox
          sx={{ fontSize: 24 }}
        />
        <Typography fontWeight='bold' >{t('sideBar.dashboard')}</Typography>
      </MenuItem>

      {/* <MenuItem
        onClick={(event) => handleChange(event, 1)}
      >
        <SvgIcon
          component={IconClaim}
          viewBox="0 0 24 24"
          sx={{ fontSize: 24, fill: 'none', stroke: localStorage.getItem('mui-mode') === 'light' ? '#000' : '#FFF' }}
        />
        <Typography fontWeight='bold' >{t('sideBar.claim')}</Typography>
      </MenuItem>

      <MenuItem
        onClick={(event) => handleChange(event, 5)}
      >
        <SvgIcon
          component={IconSwap}
          sx={{ fontSize: 24 }}
        />
        <Typography fontWeight='bold' >{t('sideBar.swap')}</Typography>
      </MenuItem>

      <MenuItem
        onClick={(event) => handleChange(event, 6)}
      >
        <SvgIcon
          component={IconChat}
          sx={{ fontSize: 24 }}
        />
        <Typography fontWeight='bold' >{t('sideBar.chat')}</Typography>
      </MenuItem> */}

      {showAdminMonitor && <MenuItem
        onClick={(event) => handleChange(event, 2)}
      >
        <SvgIcon
          component={IconGraph}
          sx={{ fontSize: 24 }}
        />
        <Typography fontWeight='bold' >Adm Monit</Typography>
      </MenuItem>
      }

      <MenuItem onClick={(event) => handleChange(event, 4)}>
        <SvgIcon
          component={IconApps}
          /* onClick apps */ inheritViewBox
          sx={{ fontSize: 24 }}
        />
        <Typography fontWeight='bold' >{t('sideBar.apps')}</Typography>
      </MenuItem>

      <MenuItem onClick={(event) => handleChange(event, 3)}>
        {profile?.data && profile?.data?.profileImg ? (
          <img
            src={` ${profile?.data?.profileImg}`}
            alt="Imagem Codificada em Base64"
            height="20px"
            width="20px"
            style={{ borderRadius: "100%" }}
          />
        ) : (
          <SvgIcon
            component={ConetAvatar}
            inheritViewBox
            sx={{ fontSize: 32 }}
          />
        )}
        <Typography fontWeight={500} fontSize="12px">
          {profile?.data?.nickname || "Anonymous User"}
        </Typography>
      </MenuItem>
    </Box>
  ) : null;
};

const DashBoard = () => {
  const {
    hasContainer,
    isUnlocked,
    showMiner,
    showAppStore,
    setShowMiner,
    localDaemon,
    isInitializing,
    setShowAppStore,
    locale,
    isNodeExplorerOpen,
    isModalOpen,
    setLocale,
    setIsNodeExplorerOpen,
    dAPPOpen,
    menuValue
  } = useAppState();
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | Element>();
  const openMenu = Boolean(anchorEl);
  const { mode, setMode } = useColorScheme();
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);

  const ShowApp = () => {
    switch (dAPPOpen) {
      case "miner": {
        return (
          <Miner />
        );
      }

      case "cloudNode": {
        return <CloudNode />;
      }

      case "profileTEST": {
        return <ProfileTest />;
      }

      case "apps": {
        return <NoDaemon />;
      }

      // case "chat": {
      //   return <Chat />
      // }

      // case "swap": {
      //   return <SwapFeatures />
      // }

      case "adminMonitor": {
        return <AdminMonitor />
      }

      // case "claim": {
      //   return <Claim />
      // }

      default: {
        return <NodeExplorer />;
      }
    }
  };

  const MenuSideBar = () => {
    const { setTheme, theme } = useAppState()
    const [currentProfile, setCurrentProfile] = useState<IProfile>();
    const [currentName, setCurrentName] = useState<string>()
    const [isCollapsed, setCollapsed] = useState<boolean>(false)
    const profile = useTypedSelector(state => state.appState.activeProfile)
    const [profileVer, setProfileVer] = useState<number>(0)
    const authKey = useTypedSelector(state => state.appState.pass)

    const showAdminMonitor = useRefreshStore(state => state.showAdminMonitor)

    const { userProfile, setUserProfile } = useUserProfile();
    const { t } = useTranslation()
    /*     const {i18n} = useTranslation()
        const {changeLanguage} = useLanguage() */

    useEffect(() => {
      const tmpShowAdminMonitor = localStorage.getItem("showAdminMonitor")

      if (!tmpShowAdminMonitor) return

      const parsedShowAdminMonitor = JSON.parse(tmpShowAdminMonitor)
      useRefreshStore.getState().setShowAdminMonitor(parsedShowAdminMonitor)
    }, []);

    useEffect(() => {
      listeningVersionHook(setProfileVer)
    })

    useEffect(() => {
      setCurrentProfile(userProfile)

    }, [profile])

    const animeCONET = () => {
      return (
        <LogoImage size={40} color={mode === "light" ? "#48473a" : "#e5e2d9"} />
      );
    };

    const handleChange = (event: SyntheticEvent | null, newValue: number) => {
      if (event !== null) {
        switch (newValue) {
          case 0: {
            store.dispatch(setMenuValue(newValue));
            if (dAPPOpen === "miner") {
              return;
            }

            return store.dispatch(setDAPPOpen("miner"));
          }

          // case 1: {
          //   store.dispatch(setMenuValue(newValue));
          //   if (dAPPOpen === "claim") {
          //     return;
          //   }

          //   return store.dispatch(setDAPPOpen("claim"));
          // }

          case 2: {
            store.dispatch(setMenuValue(newValue));
            if (dAPPOpen === "adminMonitor") {
              return;
            }

            return store.dispatch(setDAPPOpen("adminMonitor"));
          }

          case 3: {
            store.dispatch(setMenuValue(newValue));
            if (dAPPOpen === "profileTEST") {
              return;
            }

            return store.dispatch(setDAPPOpen("profileTEST"));
          }

          case 4: {
            store.dispatch(setMenuValue(newValue));
            if (dAPPOpen === "apps") {
              return;
            }

            return store.dispatch(setDAPPOpen("apps"));
          }

          // case 5:
          //   store.dispatch(setMenuValue(newValue));
          //   if (dAPPOpen === "swap") return;

          //   return store.dispatch(setDAPPOpen("swap"))

          // case 6:
          //   store.dispatch(setMenuValue(newValue));
          //   if (dAPPOpen === "chat") return;

          //   return store.dispatch(setDAPPOpen("chat"))

          default: {
            store.dispatch(setMenuValue(newValue));
            if (dAPPOpen === "nodes") {
              return;
            }
            return store.dispatch(setDAPPOpen("nodes"));
          }
        }
      }
    };

    const ThemeMenuClick = () => {
      if (theme === "Dark") {
        setMode(`light`);
        setTheme("Light");
      } else {
        setMode(`dark`);
        setTheme("Dark");
      }
    };

    const COLORS_TOP = ["#587dff", "#d473fa", "#8e4ce1", "#577dff"]

    const color = useMotionValue(COLORS_TOP[0]);

    useEffect(() => {
      // Animate the color changes in a loop
      animate(color, COLORS_TOP, {
        ease: "easeInOut",  // Easing function for smooth transitions
        duration: 75,       // Duration for the entire color transition cycle
        repeat: Infinity,   // Repeat the animation infinitely
        repeatType: "mirror", // Reverses the animation direction on each cycle
      });
    }, [color]);

    const backgroundImage = useMotionTemplate`radial-gradient(100% 100% at 50% 50%, #020617 -100%, ${color})`;

    return (
      <>
        <Box
          width={isCollapsed ? "96px" : "228px"}
          position="relative"
          id='button-collapse'
          bgcolor={localStorage.getItem('mui-mode') === "light" ? "#ffffff" : "#111113"}
          display={{
            xs: !isUnlocked ? 'none' : "none",
            sm: !isUnlocked ? 'none' : "none",
            md: !isUnlocked ? 'none' : "none",
            xm: !isUnlocked ? 'none' : "flex",
            lg: !isUnlocked ? 'none' : "flex",
            xl: !isUnlocked ? 'none' : "flex",
          }}
          height='100svh'
          borderRight={
            mode === "light" ? "1px solid #E4E2E4" : "1px solid #474648"
          }
          flexDirection="column"
          justifyContent="space-between"
          padding="1rem"
          flexShrink={0}
        >
          <Box display="flex" flexDirection="column" gap="2rem">
            {isUnlocked && (
              <Box>{mode === "light" ? <LogoLight /> : <LogoDark />}</Box>
            )}
            {isUnlocked && (
              <>
                {/* <NetworkSwitcher isCollapsed={isCollapsed} /> */}
                <Box
                  style={{
                    height: "28px",
                    width: "28px",
                    background: "white",
                    borderRadius: "50%",
                    position: "absolute",
                    right: "-12px",
                    cursor: "pointer",
                    border: "1px solid #e4e4e4",
                    padding: "1px",
                    marginTop: '50px'
                  }}
                  onClick={() => setCollapsed(!isCollapsed)}
                >
                  <SvgIcon
                    component={isCollapsed ? IconArrowRight : IconArrowLeft}
                    inheritViewBox
                    sx={{ fontSize: 24 }}
                  />
                </Box>

                <MenuOption
                  {...a11yProps(0)}
                  style={{ backgroundColor: menuValue == 0 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                  onClick={(event) => handleChange(event, 0)}
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconDashboard}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <Typography fontWeight='bold'>{t('sideBar.dashboard')}</Typography>}
                </MenuOption>

                {/* <MenuOption {...a11yProps(0)}
                  style={{ backgroundColor: menuValue == 1 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                  onClick={(event) =>
                    handleChange(event, 1)
                  }
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconClaim}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}><Typography fontWeight='bold'>{t('sideBar.claim')}</Typography>
                    <motion.section
                      style={{ backgroundImage, padding: "1px", borderRadius: '8px' }}
                      className="relative min-h-screen"
                    >
                      <Typography padding={'4px 12px'} fontSize={'8px'} fontWeight='bold' borderRadius={'8px'} color={'#fff'}>{t('sideBar.new')}</Typography>
                    </motion.section>
                  </Box>}
                </MenuOption> */}

                {/* <MenuOption {...a11yProps(0)}
                  style={{ backgroundColor: menuValue == 5 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                  onClick={(event) =>
                    handleChange(event, 5)
                  }
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconSwap}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}><Typography fontWeight='bold'>{t('sideBar.swap')}</Typography>
                    <motion.section
                      style={{ backgroundImage, padding: "1px", borderRadius: '8px' }}
                      className="relative min-h-screen"
                    >
                      <Typography padding={'4px 12px'} fontSize={'8px'} fontWeight='bold' borderRadius={'8px'} color={'#fff'}>{t('sideBar.new')}</Typography>
                    </motion.section>
                  </Box>}
                </MenuOption> */}

                {/* <MenuOption {...a11yProps(0)}
                  style={{ backgroundColor: menuValue == 6 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                  onClick={(event) =>
                    handleChange(event, 6)
                  }
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconChat}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}><Typography fontWeight='bold'>{t('sideBar.chat')}</Typography>
                    <motion.section
                      style={{ backgroundImage, padding: "1px", borderRadius: '8px' }}
                      className="relative min-h-screen"
                    >
                      <Typography padding={'4px 12px'} fontSize={'8px'} fontWeight='bold' borderRadius={'8px'} color={'#fff'}>{t('sideBar.new')}</Typography>
                    </motion.section>
                  </Box>}
                </MenuOption> */}

                {showAdminMonitor && <MenuOption {...a11yProps(0)}
                  style={{ backgroundColor: menuValue == 2 ? (mode === "light" ? "#EFF2FF" : "#363E59") : 'transparent' }}
                  onClick={(event) =>
                    handleChange(event, 2)
                  }
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    style={{ display: 'flex' }}
                  >
                    <SvgIcon
                      component={IconGraph}
                      inheritViewBox
                      sx={{ fontSize: 24 }}
                    />
                  </motion.div>
                  {!isCollapsed && <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}><Typography fontWeight='bold'>{t('sideBar.admin')}</Typography>
                  </Box>}
                </MenuOption>
                }
              </>
            )}
          </Box>

          <Box display="flex" flexDirection="column" gap="1rem">
            {isUnlocked && (
              <>
                <MenuOption
                  {...a11yProps(3)}
                  onClick={(event) => handleChange(event, 4)}
                >
                  <SvgIcon
                    component={IconApps}
                    inheritViewBox
                    sx={{ fontSize: 24 }}
                  />
                  {!isCollapsed && <Typography fontWeight='bold'>{t('sideBar.apps')}</Typography>}
                </MenuOption>
                <Box display="flex" gap={isCollapsed ? "16px" : "16px"} alignItems="center" justifyContent="flex-start" flexDirection={'column'} padding={'8px 16px'}>
                  <Box width={"100%"} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} gap={'8px'}
                    onClick={ThemeMenuClick}
                    style={{ cursor: 'pointer' }}
                  >
                    <SvgIcon
                      component={mode === "light" ? IconLightTheme : IconDarkTheme}
                      inheritViewBox
                      sx={{ fontSize: 32 }}
                    />

                    <Box fontWeight='bold'>{isCollapsed ? "" : t('sideBar.theme')}</Box>
                  </Box>

                  {LanguageSwitcher(isCollapsed)}
                </Box>
                <Box
                  borderTop={
                    mode === "light" ? "1px solid #E4E2E4" : "1px solid #474648"
                  }
                />
                <Box
                  onClick={(event) => handleChange(event, 3)}
                  display="flex"
                  gap="0.5rem"
                  alignItems="center"
                  bgcolor={mode === "light" ? "#EFF2FF" : "#363E59"}
                  /* onClick account */
                  p="1rem"
                  borderRadius="1rem"
                  style={{ cursor: 'pointer' }}
                >{
                    (userProfile && userProfile.data) && userProfile?.data?.profileImg ?
                      (<img src={` ${userProfile?.data?.profileImg}`} alt="Imagem Codificada em Base64" height='20px' width='20px' style={{ borderRadius: '100%' }} />) :
                      <SvgIcon
                        component={ConetAvatar}
                        inheritViewBox
                        sx={{ fontSize: 32 }}
                      />
                  }

                  {!isCollapsed && <Typography fontWeight={500} fontSize="12px">
                    {userProfile?.data?.nickname || intl.formatMessage({ id: 'platform.ProfileDropdown.CurrentProfileItem.AnonymousUser' })}
                  </Typography>}
                </Box>
              </>
            )}
          </Box>
        </Box>
        <MobileSidebar handleChange={handleChange} profile={userProfile} currentProfile={currentProfile} />
      </>
    );
  };

  const AppStart = () => {
    const [step, setStep] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <StackContainer /* sx={{ height: isMobile ? '91vh' : '100vh' }} */>
        <Container
          // maxWidth="lg"
          sx={{
            height: "100%",
            width: "100%",
            overflowX: "auto",
            padding: '0!important',
            paddingTop: '40px',
            margin: '0 auto',
            maxWidth: '100%!important'
          }}
        >
          {!isInitializing && (
            <Stack
              sx={{ width: "100%", height: "100%" }} alignItems="center"
            >
              {/* <ShowApp /> */}

              {hasContainer && isUnlocked ? (
                <>
                  <ShowApp />
                  <FooterPlatform />
                </>
              ) : (
                <WelcomeToConet onClickStep={(e) => setStep(e)} />
              )}
            </Stack>
          )}
          {isInitializing && (
            <Stack
              sx={{
                width: "100%",
                height: "100vh",
                padding: "2rem",
                margin: "-3rem 0 0 0",
              }}
              alignItems="center"
              justifyContent="center"
            >
              <LogoImage color="grey" />
            </Stack>
          )}
        </Container>
      </StackContainer>
    );
  };

  return (
    <StackContainer
      direction={{ xs: "column", sm: "row" }}
      sx={{ minHeight: "25rem" }}
    >
      <MenuSideBar />
      <AppStart />
    </StackContainer>
  );
};

export default DashBoard;
